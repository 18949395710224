import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import MUICheckbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import MuiTooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/RemoveRedEye";

import RadioGroup from "../../common/RadioGroup";
import HelpLabel from "../../common/HelpLabel";
import Checkbox from "../../common/Checkbox";
import TextField from "../../common/TextField";
import CustomTableCell from "../../common/TableCell";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import Select from "../../common/Select";
import Snackbar from "../../common/Snackbar";
import TablePaginationActionsWrapped from "../../common/Paginator";
import WarningDialog from "../../common/WarningDialog";
import { WorkPlanComment, WorkPlanReview } from "../models";
import { createSelector } from "../../common/orm";
import { WorkPlanTab } from "../../home/models";

const getReview = createSelector(
    (state, ownProps) => parseInt(ownProps.proposal.id),
    (schema, proposal) => {
        return schema.WorkPlanReview.filter(x => x.proposal_id === proposal).first();
    }
);

const getComments = createSelector(
    (state, ownProps) => parseInt(ownProps.proposal.id),
    (schema, proposal) => {
        var wpc = schema.WorkPlanComment.filter(x => x.proposal_id === proposal)
            .orderBy("id")
            .toModelArray()
            .map(x => {
                return {
                    user_: x.user,
                    uid: x.ref.user,
                    ...x.ref
                };
            });
        wpc.forEach((w, i) => w.commentid = i + 1)
        return wpc;
    }
);
const getTab = WorkPlanTab.selectMessage(28);

const options = [
    {
        label: "Project Manager",
        value: "Applicant"
    },
    {
        label: "Member",
        value: "Member"
    },
    {
        label: "Staff",
        value: "Staff"
    }
];

const styles = theme => ({
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    hide: {
        display: "none"
    },
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    },
    centerAlign: {
        textAlign: "center"
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    dialogTitleMargin: {
        marginRight: "48px"
    },
    closeButton: {
        position: "absolute",
        right: 8,
        top: 8
    },
    box: {
        "& textarea": {
            color: "black"
        }
    },
    flex: {
        flex: 1,
        fontWeight: 500,
        fontSize: "1.15rem",
        color: "#495057",
        lineHeight: 1.2
    }
});

const columnData = [
    { id: "actions", numeric: false, label: "Actions", width: "20px" },
    { id: "id", numeric: true, label: "Comment ID", allowSort: true },
    { id: "step", numeric: true, label: "Step", allowSort: true },
    { id: "comment_for", numeric: false, label: "Comment For", allowSort: true },
    { id: "date", numeric: false, label: "Date Created", allowSort: true, date: true },
    { id: "uid", numeric: true, label: "Created By", allowSort: true },
    { id: "comment", numeric: false, label: "Staff Note/Comment", allowSort: true },
    { id: "resolved", numeric: false, bool: true, label: "Resolved", allowSort: true, aLabel: "Resolved_Comment" }
];

class Final_Comments extends Component {
    state = {
        dont: false,
        page: 0,
        rowsPerPage: 25,
        order: "desc",
        orderBy: "",
        CommentDialogObj: null,
        WarningDialogObj: null,
        error: false,
        message: "",
        commentFilter: "All"
    };

    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Work Plan Staff Comments - LCCMR";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            if (_this.props.appContainer.current) {
                _this.props.appContainer.current.scrollTop();
            }
        }, 1);
    }

    componentDidUpdate() {
        //resets form to include id after creation of score
        if (this.state.dont) {
            this.setState({ dont: false });
        }
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        this.props.comments.sort(function(a, b) {
            var date = columnData.find(cD => cD.id === orderBy).date;
            var numeric = columnData.find(cD => cD.id === orderBy).numeric;
            var bool = columnData.find(cD => cD.id === orderBy).bool;
            if (date) {
                if (order === "desc") return new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1;
                else return new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1;
            } else if (bool) {
                if (order === "desc") return b[orderBy] - a[orderBy];
                else return a[orderBy] - b[orderBy];
            } else if (numeric) {
                if (order === "desc") return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
                else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
            } else {
                if (order === "desc") return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });

        this.setState({ order, orderBy });
    };

    resolved(e, i) {
        const { ormWorkPlanCommentUpdate } = this.props;
        ormWorkPlanCommentUpdate({
            id: i,
            resolved: e.target.checked
        });
    }

    cancelDeleteRecord = () => {
        this.setState({ WarningDialogObj: false });
    };

    deleteRecord = () => {
        this.props.ormWorkPlanCommentDelete(this.state.WarningDialogObj.id);
        this.setState({ WarningDialogObj: null });
    };

    cancelDeleteRecord = () => {
        this.setState({ WarningDialogObj: null });
    };

    handleClose = () => {
        this.setState({ CommentDialogObj: null });
    };

    saveComment = values => {
        this.props.ormWorkPlanCommentUpdate({
            ...values
        });
        this.handleClose();
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };

    saveReview = (values, fromStepper) => {
        const { history, handleNext, handlePrev, plan, is_read_only, ormParentUpdateLocalOnly, ormWorkPlanReviewUpdate } = this.props;
        const { id } = plan;

        if (typeof fromStepper.step !== "undefined") {
            // If this is hit updateProposal initiated through a stepper click
            // Set the values of the steps to the one that was clicked.
            var temp = {};
            temp.activeStep = fromStepper["step"];
            temp.activeSubStep = fromStepper["subStep"];

            // just update locally
            if (is_read_only) {
                ormParentUpdateLocalOnly({
                    id: id,
                    ...temp
                });
                ormWorkPlanReviewUpdate({
                    ...values
                });
            } else {
                ormWorkPlanReviewUpdate({
                    ...values
                });
                ormParentUpdateLocalOnly({
                    id: id,
                    ...temp
                });
            }
        } else {
            ormWorkPlanReviewUpdate({
                ...values
            });
            if (typeof fromStepper.step === "undefined") {
                // If this is hit updateProposal was initialed by the bottom save button
                // Either go to the dashboard or call handlNext to determine the next step
                if (this.state.draftClick) {
                    history.push("/dashboard/");
                } else if (this.state.saveClick) {
                    this.setState({ saveClick: false });
                } else if (this.state.previousStep) {
                    handlePrev();
                } else {
                    handleNext();
                }
            }
        }
    };

    defaultValues = (values, proposal, plan) => {
        var defaultValues = {};
        defaultValues = { ...values };
        defaultValues["changes"] = plan.changes ? plan.changes : "";
        defaultValues["major_contributors"] = plan.major_contributors ? plan.major_contributors : "";
        defaultValues["contingencies"] = proposal.contingencies ? proposal.contingencies : "";
        return defaultValues;
    };

    render() {
        const { classes, getSteps, comments, draftPage, plan, proposal, review, ActionMenu, tab } = this.props;
        const { dont, order, orderBy, page, rowsPerPage, CommentDialogObj, WarningDialogObj, commentFilter } = this.state;

        const is_read_only = false;
        const locked =
            proposal.status === "Draft Work Plan Accepted" || proposal.status === "Final Work Plan Accepted" || proposal.status === "Final Work Plan Approved" || proposal.status === "Project Completed";

        const hidePMInfo = proposal.status.includes("Due") || proposal.status.includes("Needed");

        //const comments_locked = proposal.status === "Final Work Plan Approved";
        const comments_locked = false;

        var filteredComments = comments;

        if (commentFilter !== "All") {
            filteredComments = comments.filter(x => (commentFilter !== "Resolved" ? x.resolved !== true : !!x.resolved));
        }

        return (
            <>
                {!dont && (
                    <Form
                        getApi={el => (this.form = el)}
                        key={plan.id}
                        dontValidateOnMount={true}
                        validateOnSubmit={true}
                        defaultValues={this.defaultValues(review, proposal, plan)}
                        onSubmit={(values, fromStepper) => this.saveReview(values, fromStepper)}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <Grid container spacing={16} style={{ marginTop: 0 }}>
                                    <Grid item xs={12} lg={10} xl={7}>
                                        <Typography variant="titleAction">
                                            Staff Review and Comments for {draftPage ? " Draft " : " Final "} Work Plan
                                        </Typography>
                                        {ActionMenu}
                                    </Grid>
                                    <Grid item xs={12} lg={10} xl={7}>
                                        <Typography>
                                            Staff Lead for this Work Plan:{" "}
                                            {proposal.staff_lead_label}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} lg={10} xl={7}>
                                        <Typography>
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="https://www.lccmr.mn.gov/about/staff-index.html"
                                                style={{ "text-decoration": "underline" }}>
                                                Contact LCCMR Staff
                                            </a>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} lg={10} xl={7}>
                                        <TextField disabled={is_read_only} field="final_staff_comments" fullWidth multiline>
                                            <HelpLabel
                                                inputLabel="Comments #1 for LCCMR staff (i.e., internal)"
                                                htmlText={true}
                                                showLabel={true}
                                                helpText={tab.tab_help_texts.find(tht => tht.label === "Comments #1 for LCCMR staff (i.e., internal)").text}
                                            />
                                        </TextField>
                                        <TextField disabled={is_read_only} field="final_staff_comments2" fullWidth multiline>
                                            <HelpLabel
                                                inputLabel="Comments #2 for LCCMR staff (i.e., internal)"
                                                htmlText={true}
                                                showLabel={true}
                                                helpText={tab.tab_help_texts.find(tht => tht.label === "Comments #2 for LCCMR staff (i.e., internal)").text}
                                            />
                                        </TextField>
                                        <TextField disabled={is_read_only} field="final_member_comments" fullWidth multiline>
                                            <HelpLabel
                                                inputLabel="Comments #1 for Members (i.e., internal)"
                                                htmlText={true}
                                                showLabel={true}
                                                helpText={tab.tab_help_texts.find(tht => tht.label === "Comments #1 for Members (i.e., internal)").text}
                                            />
                                        </TextField>
                                        <TextField disabled={is_read_only} field="final_member_comments2" fullWidth multiline>
                                            <HelpLabel
                                                inputLabel="Comments #2 for Members (i.e., external)"
                                                htmlText={true}
                                                showLabel={true}
                                                helpText={tab.tab_help_texts.find(tht => tht.label === "Comments #2 for Members (i.e., external)").text}
                                            />
                                        </TextField>
                                        <TextField disabled={is_read_only} field="internal_pm" fullWidth multiline>
                                            <HelpLabel
                                                inputLabel="Comments #1 for Project Manager (i.e., internal)"
                                                htmlText={true}
                                                showLabel={true}
                                                helpText={tab.tab_help_texts.find(tht => tht.label === "Comments #1 for Project Manager (i.e., internal)").text}
                                            />
                                        </TextField>
                                        <TextField disabled={is_read_only} field="external_pm" fullWidth multiline>
                                            <HelpLabel
                                                inputLabel="Comments #2 for Project Manager (i.e., external)"
                                                htmlText={true}
                                                showLabel={true}
                                                helpText={tab.tab_help_texts.find(tht => tht.label === "Comments #2 for Project Manager (i.e., external)").text}
                                            />
                                        </TextField>
                                        {!hidePMInfo && (
                                            <>
                                                <TextField className={classes.box} label="Contingencies" field="contingencies" multiline fullWidth disabled />
                                                <TextField
                                                    className={classes.box}
                                                    label="Changes from proposal to workplan stage."
                                                    field="changes"
                                                    multiline
                                                    fullWidth
                                                    disabled
                                                />          
                                                {parseInt(plan.ml_year) >= 2025 && (                                   
                                                    <TextField
                                                        className={classes.box}
                                                        label="Provide the name(s) and organization(s) of additional individuals assisting in the completion of this project"
                                                        field="major_contributors"
                                                        multiline
                                                        fullWidth
                                                        disabled
                                                    />
                                                )}
                                            </>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} lg={10} xl={7}>
                                        <Form dontValidateOnMount={true} validateOnSubmit={true} defaultValues={{ filters: "All" }}>
                                            {formApi => (
                                                <form style={{ float: "right", marginTop: -24 }} onSubmit={formApi.submitForm}>
                                                    <RadioGroup
                                                        field="filters"
                                                        name="filters"
                                                        eventHandle={val => this.setState({ commentFilter: val })}
                                                        fullWidth
                                                        options={[
                                                            { label: "All", value: "All" },
                                                            { label: "Resolved", value: "Resolved" },
                                                            { label: "Unresolved", value: "Unresolved" }
                                                        ]}
                                                        alignment={true}
                                                        label="Search"
                                                    />
                                                </form>
                                            )}
                                        </Form>
                                        <Table className={classes.table}>
                                            <EnhancedTableHead stickyHeader columnData={columnData} order={order} orderBy={orderBy} onRequestSort={this.handleRequestSort} />
                                            <TableBody>
                                                {filteredComments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, index) => {
                                                    return (
                                                        <TableRow key={n.id + "term"}>
                                                            <CustomTableCell className={classes.nowrap}>
                                                                <MuiTooltip title="Edit">
                                                                    <Button
                                                                        disabled={comments_locked}
                                                                        className={comments_locked ? classes.hide : classes.deleteWidth}
                                                                        aria-label="Edit"
                                                                        onClick={() => this.setState({ CommentDialogObj: n })}>
                                                                        <EditIcon color="primary" />
                                                                    </Button>
                                                                </MuiTooltip>
                                                                <MuiTooltip title="View">
                                                                    <Button
                                                                        className={comments_locked ? classes.deleteWidth : classes.hide}
                                                                        aria-label="View"
                                                                        onClick={() => this.setState({ CommentDialogObj: n })}>
                                                                        <ViewIcon color="primary" />
                                                                    </Button>
                                                                </MuiTooltip>
                                                                <MuiTooltip title="Delete">
                                                                    <Button
                                                                        disabled={comments_locked}
                                                                        className={classes.deleteWidth}
                                                                        aria-label="Delete"
                                                                        onClick={() => this.setState({ WarningDialogObj: n })}>
                                                                        <DeleteIcon color="primary" />
                                                                    </Button>
                                                                </MuiTooltip>
                                                            </CustomTableCell>
                                                            <CustomTableCell>{n.commentid}</CustomTableCell>
                                                            <CustomTableCell>{getSteps[n.step]}</CustomTableCell>
                                                            <CustomTableCell>
                                                                {n.comment_for === "Applicant" ? "Project Manager" : n.comment_for}
                                                            </CustomTableCell>
                                                            <CustomTableCell>{n.date}</CustomTableCell>
                                                                <CustomTableCell>
                                                                    {n.user_.first_name + " " + n.user_.last_name}
                                                                </CustomTableCell>
                                                            <CustomTableCell>{n.comment}</CustomTableCell>
                                                            <CustomTableCell>
                                                                <MUICheckbox
                                                                    color="primary"
                                                                    inputProps={{
                                                                        "aria-labelledby": `Resolved_Comment`
                                                                    }}
                                                                    disabled={comments_locked}
                                                                    checked={n.resolved}
                                                                    onClick={e => this.resolved(e, n.id)}
                                                                />
                                                            </CustomTableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                                {filteredComments.length < 1 && (
                                                    <TableRow>
                                                        <CustomTableCell colSpan={7} className={classes.centerAlign}>
                                                            <caption style={{display:"inline"}}>No Comments Found</caption>
                                                        </CustomTableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                            {filteredComments.length > 25 && (
                                                <TableFooter>
                                                    <TableRow>
                                                        <TablePagination
                                                            colSpan={7}
                                                            count={filteredComments.length}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            onChangePage={this.handleChangePage}
                                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                            ActionsComponent={TablePaginationActionsWrapped}
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            )}
                                        </Table>
                                    </Grid>
                                    <Grid item xs={12} lg={10} xl={7} className={classes.rightAlign}>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            style={{ float: "left" }}
                                            disabled={is_read_only || hidePMInfo}
                                            onClick={() => this.setState({ draftClick: false, previousStep: true })}
                                            className={classes.button}>
                                            Save and Return to Previous Step
                                        </Button>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            disabled={is_read_only}
                                            onClick={() => this.setState({ saveClick: true })}
                                            className={classes.button}>
                                            Save
                                        </Button>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            disabled={is_read_only}
                                            onClick={() => this.setState({ draftClick: true })}
                                            className={classes.button}>
                                            Save Draft and Return to Dashboard
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Form>
                )}
                <Dialog role="main" aria-label={comments_locked ? "View Staff Comment" : "Edit Staff Comment"} open={CommentDialogObj ? true : false} onClose={this.handleClose} disableBackdropClick={true}>
                    <Form defaultValues={CommentDialogObj} dontValidateOnMount={true} onSubmit={values => this.saveComment(values)}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <DialogTitle className={classes.dialogTitleMargin} id="form-dialog-title">
                                    <Typography variant="h1" className={classes.flex}>{comments_locked ? "View" : "Edit"} Staff Comment</Typography>
                                    <IconButton aria-label="Close Dialog" className={classes.closeButton} onClick={this.handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12}>
                                            <Checkbox field="resolved" label="Comment Resolved" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Select options={options} field="comment_for" label="Comment For" fullWidth disabled={comments_locked} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField field="comment" label="Comments" multiline fullWidth disabled={comments_locked} />
                                        </Grid>
                                        <Typography>{locked}</Typography>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => this.setState({ CommentDialogObj: null })}>Cancel</Button>
                                    <Button color="primary" variant="contained" type="submit">
                                        Save
                                    </Button>
                                </DialogActions>
                            </form>
                        )}
                    </Form>
                </Dialog>
                <Snackbar handleSnackbarClose={() => this.setState({ error: false })} snackbarOpen={this.state.error} message={this.state.message} />
                <WarningDialog
                    confirmText={"Yes"}
                    confirmAction={this.deleteRecord}
                    cancelText={"No"}
                    cancelAction={this.cancelDeleteRecord}
                    open={!!WarningDialogObj}
                    title="Delete Comment record"
                    text={"Are you sure you want to delete this Comment record?"}
                />
            </>
        );
    }
}

Final_Comments = connect(
    (state, ownProps) => ({
        authState: state.auth,
        review: getReview(state, ownProps),
        tab: getTab(state, ownProps),
        comments: getComments(state, ownProps)
    }),
    {
        ...WorkPlanComment.actions,
        ...WorkPlanReview.actions
    }
)(Final_Comments);

export default withStyles(styles)(withRouter(Final_Comments));
