import React, { Component } from "react";
//import { withRouter, Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import { Form } from "react-form";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
//import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core";
import AppContainer from "../common/AppContainer";
import PageHeader from "../common/PageHeader";
//import TextField from "../common/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Input from "@material-ui/core/Input";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { DatePicker as MuiDatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DateFnsUtils from "@date-io/date-fns";
import { createSelector } from "../common/orm";

const styles = theme => ({
    expansionSummaryRoot: {
        backgroundColor: "#eee",
        minHeight: 64,
        borderBottom: "1px dotted"
    },
    expansionIcon: {
        marginTop: 6,
        marginBottom: -6,
        marginRight: 8
    },
    button: {
        marginTop: "10px"
    },
    selectbox: {
        margin: "5px 0px 10px 0px",
        backgroundColor: "#fff"
    },
    gridtop: {
        borderTop: "1px dashed #ddd",
        marginTop: "12px",
        paddingTop: "12px !important"
    },
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#eef7fa"
        }
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    centerAlign: {
        textAlign: "center"
    },


    
    rootLabel: {
        marginLeft: 12,
        marginTop: 3,
        whiteSpace: "nowrap"
    },
    rootLabelShrink: {
        marginLeft: 0,
        marginBottom: -16,
        marginTop: 8,
        position: "relative",
        whiteSpace: "normal"
    },
    bootstrapInput: {
        borderRadius: 4,
        border: "1px solid #979797",
        padding: "8px 12px",
        width: "calc(100% - 24px)",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            boxShadow: "0 0 0 0.2rem rgba(0,64,110,.50)"
        },
        backgroundColor: "#fff",
        fontSize: 14,
        fontWeight: 300
    },
    bootstrapInputError: {
        "& input": {
            border: "1px solid #be0000"
        }
    },
    disabled: {
        backgroundColor: "#EEEEEE"
    }
});

const getAllProposals = createSelector(schema => {
    return schema.ProposalView.all()
        .orderBy("proposal_unique_id", "desc")
        .toModelArray();
});

const getAllDraftWorkplans = createSelector(schema => {
    return schema.DraftWorkPlanView.all()
        .orderBy("proposal_unique_id", "desc")
        .toModelArray();
});

const getAllFinalWorkplans = createSelector(schema => {
    return schema.FinalWorkPlanView.all()
        .orderBy("proposal_unique_id", "desc")
        .toModelArray();
});

const getStaff = createSelector(schema => {
    return schema.User.filter(x => x.role === "Staff")
        .orderBy(["is_active", "last_name"], ["desc", "asc"])
        .toModelArray()
        .map(x => ({ value: x.id, label: x.first_name + " " + x.last_name }));
});

class Reports extends Component {
    state = {
        valid_years: [],
        fr_years: ["2020","2021","2022","2023","2024","2025","2026","2027","2028","2029","2030","2031","2032","2033","2034","2035","2036","2037","2038","2039","2040"],
        proposal_year: [],
        proposal_batch_formats: [],
        proposal_filters: [],
        eval_order: "",
        mrp_year: [],
        mrp_report: "",
        mrp_sort: "",
        hide_mrp_sort: true,
        valid_dw_years: [],
        dw_year: [],
        dw_batch_formats: [],
        dw_filters: [],
        valid_fw_years: [],
        fw_year: [],
        fw_batch_formats: [],
        fw_filters: [],
        valid_leg_years: [],
        leg_years: [],
        dbl_year: [],
        allproj_year: [],
        valid_ap_years: [],
        api_year: [],
        api_format: "",
        su_checkbox: false,
        su_date_from: null,
        su_date_to: null,
        su_staff: [],
        su_year: [],
        valid_staff: [],
        fr_year: [],
        ap_year: [],
        subject_year: [],
    };

    componentDidMount() {
        document.title = "Administration - Reports";
        const { authState, history } = this.props;
        if (authState && authState.user && authState.user.role !== "Staff") {
            history.push("/dashboard");
        }
    }

    get_valid_years = (valid, valid_state, all) => {
        if (valid.length === 0 && all.length > 0) {
            var temp_valid_years = [];
            for (var x of all) {
                if (valid_state === "valid_ap_years") {
                    if (x.status !== "Final Work Plan Approved")
                        continue;
                }
                var ml_year = x.proposal_unique_id.split("-")[0];
                if (temp_valid_years.includes(ml_year) === false) {
                    temp_valid_years.push(ml_year);
                }
            }
            temp_valid_years = temp_valid_years.sort();
            if (valid_state === "valid_years") {
                this.setState({ [valid_state]: ["All", ...temp_valid_years], valid_leg_years: [...temp_valid_years] });
            } else {
                this.setState({ [valid_state]: ["All", ...temp_valid_years] });
            }
        }
    };

    get_valid_staff = (valid, valid_state, all) => {
        if (valid.length === 0 && all.length > 0) {
            var temp_valid_years = [];
            for (var x of all) {
                temp_valid_years.push(x.label);
            }
            this.setState({ [valid_state]: ["All", ...temp_valid_years] });
        }
    };

    handleChangeProposalYear = event => {
        var year_list = event.target.value;
        if (year_list.indexOf("reset") > -1) {
            this.setState({ proposal_year: [] });
            return;
        }

        var posAll = year_list.indexOf("All");
        var hasAll = posAll > -1;
        var hadAll = this.state.proposal_year.indexOf("All") > -1;
        if (hasAll === true && hadAll === false) {
            year_list = [...this.state.valid_years];
        }
        if (hasAll && year_list.length < this.state.valid_years.length) {
            year_list.splice(posAll, 1);
        }

        this.setState({ proposal_year: year_list });
    };

    handleChangeProposalBatchFormat = event => {
        this.setState({ proposal_batch_formats: event.target.value });
    };

    handleChangeProposalFilter = event => {
        this.setState({ proposal_filters: event.target.value });
    };

    handleChangeEvalOrder = event => {
        this.setState({ eval_order: event.target.value });
    };

    handleChangeMRPYear = event => {
        var year_list = event.target.value;
        var posAll = year_list.indexOf("All");
        var hasAll = posAll > -1;
        var hadAll = this.state.mrp_year.indexOf("All") > -1;
        if (hasAll === true && hadAll === false) {
            year_list = [...this.state.valid_years];
        }
        if (hasAll && year_list.length < this.state.valid_years.length) {
            year_list.splice(posAll, 1);
        }
        this.setState({ mrp_year: year_list });
    };

    handleChangeMRPReport = event => {
        var cur_sort = this.state.mrp_sort;
        var hide_sort = true;
        if (event.target.value.includes("Report")) {
            hide_sort = false;
        } else {
            cur_sort = "";
        }
        this.setState({ mrp_report: event.target.value, mrp_sort: cur_sort, hide_mrp_sort: hide_sort });
    };

    handleChangeAPIReport = event => {
        this.setState({ api_format: event.target.value });
    };

    handleChangeMRPSort = event => {
        this.setState({ mrp_sort: event.target.value });
    };

    handleChangeDWYear = event => {
        var year_list = event.target.value;
        if (year_list.indexOf("reset") > -1) {
            this.setState({ dw_year: [] });
            return;
        }
        var posAll = year_list.indexOf("All");
        var hasAll = posAll > -1;
        var hadAll = this.state.dw_year.indexOf("All") > -1;
        if (hasAll === true && hadAll === false) {
            year_list = [...this.state.valid_dw_years];
        }
        if (hasAll && year_list.length < this.state.valid_dw_years.length) {
            year_list.splice(posAll, 1);
        }
        this.setState({ dw_year: year_list });
    };

    handleChangeDWBatchFormat = event => {
        this.setState({ dw_batch_formats: event.target.value });
    };

    handleChangeDWFilter = event => {
        this.setState({ dw_filters: event.target.value });
    };

    handleChangeFWYear = event => {
        var year_list = event.target.value;        
        if (year_list.indexOf("reset") > -1) {
            this.setState({ fw_year: [] });
            return;
        }
        var posAll = year_list.indexOf("All");
        var hasAll = posAll > -1;
        var hadAll = this.state.fw_year.indexOf("All") > -1;
        if (hasAll === true && hadAll === false) {
            year_list = [...this.state.valid_fw_years];
        }
        if (hasAll && year_list.length < this.state.valid_fw_years.length) {
            year_list.splice(posAll, 1);
        }
        this.setState({ fw_year: year_list });
    };

    handleChangeFWBatchFormat = event => {
        this.setState({ fw_batch_formats: event.target.value });
    };

    handleChangeFWFilter = event => {
        this.setState({ fw_filters: event.target.value });
    };

    handleChangeAPIYear = event => {
        var year_list = [event.target.value];
        this.setState({ api_year: year_list });
    };

    handleChangeLegYear = event => {
        var year_list = event.target.value;
        if (year_list.indexOf("reset") > -1) {
            this.setState({ leg_years: [] });
            return;
        }
        var posAll = year_list.indexOf("All");
        var hasAll = posAll > -1;
        var hadAll = this.state.leg_years.indexOf("All") > -1;
        if (hasAll === true && hadAll === false) {
            year_list = [...this.state.valid_years];
        }
        if (hasAll && year_list.length < this.state.valid_years.length) {
            year_list.splice(posAll, 1);
        }

        this.setState({ leg_years: year_list });
    };

    handleChangeDBLYear = event => {
        var year_list = [event.target.value];
        this.setState({ dbl_year: year_list });
    };

    handleChangeALLPROJYear = event => {
        var year_list = event.target.value;
        if (year_list.indexOf("reset") > -1) {
            this.setState({ allproj_year: [] });
            return;
        }
        var posAll = year_list.indexOf("All");
        var hasAll = posAll > -1;
        var hadAll = this.state.allproj_year.indexOf("All") > -1;
        if (hasAll === true && hadAll === false) {
            year_list = [...this.state.valid_years];
        }
        if (hasAll && year_list.length < this.state.valid_years.length) {
            year_list.splice(posAll, 1);
        }
        this.setState({ allproj_year: year_list });
    };

    handleSUCheckbox = event => {
        this.setState({ su_checkbox: event.target.checked });
    };

    handleSUFrom = event => {
        this.setState({ su_date_from: event });
    };

    handleSUTo = event => {
        this.setState({ su_date_to: event });
    };

    handleSUYear = event => {
        var year_list = event.target.value;
        if (year_list.indexOf("reset") > -1) {
            this.setState({ su_year: [] });
            return;
        }
        var posAll = year_list.indexOf("All");
        var hasAll = posAll > -1;
        var hadAll = this.state.su_year.indexOf("All") > -1;
        if (hasAll === true && hadAll === false) {
            year_list = [...this.state.valid_years];
        }
        if (hasAll && year_list.length < this.state.valid_years.length) {
            year_list.splice(posAll, 1);
        }
        this.setState({ su_year: year_list });
    };

    handleSUStaff = event => {
        var year_list = event.target.value;
        if (year_list.indexOf("reset") > -1) {
            this.setState({ su_staff: [] });
            return;
        }
        var posAll = year_list.indexOf("All");
        var hasAll = posAll > -1;
        var hadAll = this.state.su_staff.indexOf("All") > -1;
        if (hasAll === true && hadAll === false) {
            year_list = [...this.state.valid_staff];
        }
        if (hasAll && year_list.length < this.state.valid_staff.length) {
            year_list.splice(posAll, 1);
        }
        this.setState({ su_staff: year_list });
    };

    handleFRYear = event => {
        var year_list = event.target.value;
        if (year_list.indexOf("reset") > -1) {
            this.setState({ fr_year: [] });
            return;
        }
        var posAll = year_list.indexOf("All");
        var hasAll = posAll > -1;
        var hadAll = this.state.fr_year.indexOf("All") > -1;
        if (hasAll === true && hadAll === false) {
            year_list = [...this.state.fr_years];
        }
        if (hasAll && year_list.length < this.state.fr_years.length) {
            year_list.splice(posAll, 1);
        }
        this.setState({ fr_year: year_list });
    };

    handleAPYear = event => {
        var year_list = event.target.value;
        if (year_list.indexOf("reset") > -1) {
            this.setState({ ap_year: [] });
            return;
        }
        var posAll = year_list.indexOf("All");
        var hasAll = posAll > -1;
        var hadAll = this.state.ap_year.indexOf("All") > -1;
        if (hasAll === true && hadAll === false) {
            year_list = [...this.state.valid_ap_years];
        }
        if (hasAll && year_list.length < this.state.valid_ap_years.length) {
            year_list.splice(posAll, 1);
        }
        this.setState({ ap_year: year_list });
    };

    handleSubjectYear = event => {
        var year_list = event.target.value;
        if (year_list.indexOf("reset") > -1) {
            this.setState({ subject_year: [] });
            return;
        }
        var posAll = year_list.indexOf("All");
        var hasAll = posAll > -1;
        var hadAll = this.state.subject_year.indexOf("All") > -1;
        if (hasAll === true && hadAll === false) {
            year_list = [...this.state.valid_years];
        }
        if (hasAll && year_list.length < this.state.valid_years.length) {
            year_list.splice(posAll, 1);
        }
        this.setState({ subject_year: year_list });
    };

    render() {
        const { all_proposals, all_draft_workplans, all_final_workplans, classes, staff } = this.props;
        const {
            valid_years,
            proposal_year,
            proposal_batch_formats,
            proposal_filters,
            eval_order,
            mrp_year,
            mrp_report,
            mrp_sort,
            hide_mrp_sort,
            valid_dw_years,
            dw_year,
            dw_batch_formats,
            // dw_filters,
            valid_fw_years,
            valid_ap_years,
            fw_year,
            fw_batch_formats,
            // fw_filters,
            fr_years,
            valid_leg_years,
            leg_years,
            dbl_year, api_year, api_format, allproj_year,
            su_checkbox, su_date_from, su_date_to, su_staff, su_year, valid_staff, fr_year, ap_year, subject_year,
        } = this.state;

        let InputProps = {};
        InputProps["disableUnderline"] = true;
        InputProps["classes"] = {
            input: classes.bootstrapInput,
            error: classes.bootstrapInputError
        };

        var home = "/adm/reports";

        var draft_statuses = ["Draft In Progress", "Draft Submitted", "Draft Feedback Received"];
        var final_statuses = ["Final Submitted", "Revisions Needed", "Revisions Submitted"];

        var valid_batch_formats = ["Word Document", "PDF"];
        //var valid_prop_filters = ["Draft", "Final", "Selected", "Recommended"];
        var valid_eval_order = ["Overall High to Low", "By Category High to Low"];
        var valid_mrp_report = [
            "Evaluation Form #1",
            "Compiled Evaluation Report #1",
            "Evaluation Form #2",
            "Compiled Evaluation Report #2",
            "Member Recommendations Report",
            "Member Conflict of Interest Summary"
        ];
        var api_formats = [
            "Excel (Staff Review)",
            "CSV (Legacy Upload)",
        ];
        var valid_mrp_sort = [
            //"Overall High to Low Median Score/Allocation",
            //"Category High to Low Median Score/Allocation",
            //"Overall High to Low Average Score/Allocation",
            //"Category High to Low Average Score/Allocation",
            "Overall High to Low Members Allocating",
            "Category High to Low Members Allocating"
        ];

        var selected_num = 0;
        var recommended_num = 0;
        var draft_num = 0;
        var final_num = 0;

        this.get_valid_years(valid_years, "valid_years", all_proposals);
        this.get_valid_years(valid_dw_years, "valid_dw_years", all_draft_workplans);
        this.get_valid_years(valid_fw_years, "valid_fw_years", all_final_workplans);
        this.get_valid_years(valid_ap_years, "valid_ap_years", all_proposals);
        this.get_valid_staff(valid_staff, "valid_staff", staff);

        var api_not_set = api_year.length === 0 || !api_format

        var year_not_set = !(proposal_year.length > 0);
        var format_not_set = !(proposal_batch_formats.length > 0);
        var filter_not_set = !(proposal_filters.length > 0);
        var ordering_not_set = eval_order === "";

        var format_and_year_not_set = year_not_set || format_not_set;
        var filter_and_year_not_set = year_not_set || filter_not_set;
        var ordering_and_year_not_set = year_not_set || ordering_not_set;

        if (!year_not_set) {
            var year_filter = [...proposal_year];
            var posAll = year_filter.indexOf("All");
            if (posAll > -1) {
                year_filter.splice(posAll, 1);
            }
            for (var z of all_proposals) {
                if (year_filter.includes(z.ml_year)) {
                    if (z.selected_presentation === true) {
                        selected_num++;
                    }
                    if (z.recommended_for_funding === true) {
                        recommended_num++;
                    }
                    if (draft_statuses.includes(z.status)) {
                        draft_num++;
                    }
                    if (final_statuses.includes(z.status)) {
                        final_num++;
                    }
                }
            }
        }

        var p_year_string = proposal_year.join(",");
        var p_format_string = proposal_batch_formats
            .join(",")
            .replace(/\s+/g, "-")
            .toLowerCase();
        var filter_string = proposal_filters
            .join(",")
            .replace(/\s+/g, "-")
            .toLowerCase();

        var spe_cat_string = eval_order.includes("Category") ? "&cat=y" : "&cat=n";

        var dw_year_string = dw_year.join(",");
        var dw_format_string = dw_batch_formats
            .join(",")
            .replace(/\s+/g, "-")
            .toLowerCase();

        var dw_year_not_set = !(dw_year.length > 0);
        var dw_format_not_set = !(dw_batch_formats.length > 0);

        var dw_format_and_year_not_set = dw_year_not_set || dw_format_not_set;

        var fw_year_string = fw_year.join(",");
        var fw_format_string = fw_batch_formats
            .join(",")
            .replace(/\s+/g, "-")
            .toLowerCase();

        var fw_year_not_set = !(fw_year.length > 0);
        var fw_format_not_set = !(fw_batch_formats.length > 0);

        var fw_format_and_year_not_set = fw_year_not_set || fw_format_not_set;

        //var mrp_year_string = mrp_year.join(",");
        var mrp_year_string = mrp_year;
        var mrp_report_string = valid_mrp_report.indexOf(mrp_report).toString();
        var mrp_sort_cat_string = mrp_sort.includes("Category") ? "y" : "n";
        var mrp_sort_avg_string = mrp_sort.includes("Average") ? "y" : "n";
        var mrp_sort_allocating_string = mrp_sort.includes("Allocating") ? "y" : "n";

        var mrp_string = "";
        var hide_mrp_button = true;
        if (mrp_report === "Evaluation Form #1") {
            mrp_string = home + "/mef1/?year=" + mrp_year_string + "&rep=" + mrp_report_string;
            if (mrp_year.length > 0 && mrp_report !== "") {
                hide_mrp_button = false;
            }
        }
        if (mrp_report === "Compiled Evaluation Report #1") {
            mrp_string = home + "/mer1/?year=" + mrp_year_string + "&cat=" + mrp_sort_cat_string; //+
            // "&rep=" +
            // mrp_report_string +
            // "&avg=" +
            // mrp_sort_avg_string;
            if (mrp_year.length > 0 && mrp_report !== "" && mrp_sort !== "") {
                hide_mrp_button = false;
            }
            valid_mrp_sort = ["Overall High to Low Score", "Category High to Low Score"];
        }
        if (mrp_report === "Evaluation Form #2") {
            mrp_string = home + "/mef2/?year=" + mrp_year_string + "&rep=" + mrp_report_string;
            if (mrp_year.length > 0 && mrp_report !== "") {
                hide_mrp_button = false;
            }
        }
        if (mrp_report === "Compiled Evaluation Report #2") {
            mrp_string =
                home +
                "/mer2/?year=" +
                mrp_year_string +
                "&rep=" +
                mrp_report_string +
                "&cat=" +
                mrp_sort_cat_string +
                "&avg=" +
                mrp_sort_avg_string +
                "&alo=" +
                mrp_sort_allocating_string;
            if (mrp_year.length > 0 && mrp_report !== "" && mrp_sort !== "") {
                hide_mrp_button = false;
            }
        }
        if (mrp_report === "Member Recommendations Report") {
            mrp_string =
                home +
                "/mrr/?year=" +
                mrp_year_string +
                "&rep=" +
                mrp_report_string +
                "&cat=" +
                mrp_sort_cat_string +
                "&avg=" +
                mrp_sort_avg_string +
                "&alo=" +
                mrp_sort_allocating_string;
            if (mrp_year.length > 0 && mrp_report !== "" && mrp_sort !== "") {
                hide_mrp_button = false;
            }
        }
        if (mrp_report === "Member Conflict of Interest Summary") {
            mrp_string =
                home +
                "/mcoi/?year=" +
                mrp_year_string +
                "&rep=" +
                mrp_report_string +
                "&cat=" +
                mrp_sort_cat_string +
                "&avg=" +
                mrp_sort_avg_string;
            if (mrp_year.length > 0 && mrp_report !== "") {
                hide_mrp_button = false;
            }
        }

        var leg_year_string = leg_years.join(",");
        var leg_year_not_set = !(leg_years.length > 0);
        var dbl_year_string = dbl_year.join(",");
        var dbl_year_not_set = !(dbl_year.length > 0);
        var allproj_year_string = allproj_year.join(",");
        var allproj_year_not_set = !(allproj_year.length > 0);
        var ap_year_string = ap_year.join(",");
        var ap_year_not_set = !(ap_year.length > 0);
        var subject_year_string = subject_year.join(",");
        var subject_year_not_set = !(subject_year.length > 0);
        var fr_year_string = fr_year.join(",");
        var fr_year_not_set = !(fr_year.length > 0);
        var date_from = null
        if (su_date_from)
            date_from = su_date_from.toISOString().split('T')[0];
        var date_to = null
        if (su_date_to)
            date_to = su_date_to.toISOString().split('T')[0];
        var staff_label = "";
        su_staff.forEach(function(a) { 
            if (a !== "All") {
                staff_label += staff.find(x => x.label === a).value + ",";
            }
        })
        staff_label = staff_label.slice(0,-1);
        var su_string = (su_year.join(",") ? su_year.join(",") : "null") + "/" + (staff_label ? staff_label : "null") + "/" + su_checkbox + "/" + date_from + "/" + date_to;

        return (
            <AppContainer authenticated>
                <Grid container spacing={16}>
                    <PageHeader title="Reports Home" />
                    <Grid item xs={12}>
                        <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={false}>
                            <ExpansionPanelSummary
                                className={classes.expansionSummaryRoot}
                                expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3">
                                    <AssessmentIcon className={classes.expansionIcon} /> Proposal Reports
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={16}>
                                    <Grid item xs={12}>
                                        <Grid item xs={12} md={6} lg={3} xl={3}>
                                            <p></p>
                                            <Typography id="proposal-years-checkbox-label" variant="h3">
                                                Select Years :
                                            </Typography>
                                            <Select
                                                className={classes.selectbox}
                                                labelId="proposal-years-checkbox-label"
                                                id="proposal-years-checkbox"
                                                multiple
                                                fullWidth
                                                value={proposal_year}
                                                disabled={valid_years.length === 0}
                                                onChange={this.handleChangeProposalYear}
                                                input={<Input />}
                                                renderValue={selected => selected.join(", ")}>
                                                <MenuItem key="reset" value="reset">
                                                    <Button>Reset Values</Button>
                                                </MenuItem>
                                                {valid_years.map(year => (
                                                    <MenuItem key={year} value={year}>
                                                        <Checkbox color="primary" checked={proposal_year.indexOf(year) > -1} />
                                                        <ListItemText primary={year} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.gridtop} xs={12} md={6} lg={3} xl={3}>
                                        <Typography variant="h3">Batches</Typography>
                                        <br />
                                        <Typography id="proposal-batch-format-checkbox-label">
                                            Select Format(s)
                                        </Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="proposal-batch-format-checkbox-label"
                                            id="proposal-batch-format-checkbox"
                                            multiple
                                            fullWidth
                                            value={proposal_batch_formats}
                                            onChange={this.handleChangeProposalBatchFormat}
                                            input={<Input />}
                                            renderValue={selected => selected.join(", ")}>
                                            {valid_batch_formats.map(format => (
                                                <MenuItem key={format} value={format}>
                                                    <Checkbox color="primary" checked={proposal_batch_formats.indexOf(format) > -1} />
                                                    <ListItemText primary={format} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Button
                                            fullWidth
                                            disabled={format_and_year_not_set}
                                            className={classes.button}
                                            onClick={() =>
                                                window.open(
                                                    home +
                                                        "/bp/?year=" +
                                                        p_year_string +
                                                        "&ff=" +
                                                        p_format_string +
                                                        "&draft=y"
                                                )
                                            }>
                                            Batch Draft Proposals
                                        </Button>
                                        <br />
                                        <Button
                                            fullWidth
                                            disabled={format_and_year_not_set}
                                            className={classes.button}
                                            onClick={() =>
                                                window.open(
                                                    home + "/bp/?year=" + p_year_string + "&ff=" + p_format_string
                                                )
                                            }>
                                            Batch Proposals
                                        </Button>
                                    </Grid>
                                    <Grid item className={classes.gridtop} xs={12} md={6} lg={3} xl={3}>
                                        <Typography variant="h3">Proposal Tables</Typography>
                                        <br />
                                        <Typography id="proposal-batch-format-checkbox-label">
                                            Select Search(es)
                                        </Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="proposal-filters-checkbox-label"
                                            id="proposal-batch-filters"
                                            multiple
                                            fullWidth
                                            value={proposal_filters}
                                            onChange={this.handleChangeProposalFilter}
                                            input={<Input />}
                                            renderValue={selected => selected.join(", ")}>
                                            <MenuItem key={"Draft_filter"} value={"Draft"} disabled={draft_num === 0}>
                                                <Checkbox color="primary" checked={proposal_filters.indexOf("Draft") > -1} />
                                                <ListItemText primary={"Draft"} />
                                            </MenuItem>
                                            <MenuItem key={"Final_filter"} value={"Final"} disabled={final_num === 0}>
                                                <Checkbox color="primary" checked={proposal_filters.indexOf("Final") > -1} />
                                                <ListItemText primary={"Final"} />
                                            </MenuItem>
                                            <MenuItem
                                                key={"Selected_filter"}
                                                value={"Selected"}
                                                disabled={selected_num === 0}>
                                                <Checkbox color="primary" checked={proposal_filters.indexOf("Selected") > -1} />
                                                <ListItemText primary={"Selected"} />
                                            </MenuItem>
                                            <MenuItem
                                                key={"Recommended_filter"}
                                                value={"Recommended"}
                                                disabled={recommended_num === 0}>
                                                <Checkbox color="primary" checked={proposal_filters.indexOf("Recommended") > -1} />
                                                <ListItemText primary={"Recommended"} />
                                            </MenuItem>
                                        </Select>
                                        <Button
                                            fullWidth
                                            disabled={filter_and_year_not_set}
                                            className={classes.button}
                                            onClick={() =>
                                                window.open(
                                                    home + "/pt/?year=" + p_year_string + "&filter=" + filter_string
                                                )
                                            }>
                                            Generate Proposal Table
                                        </Button>
                                        <Button
                                            fullWidth
                                            disabled={filter_and_year_not_set}
                                            className={classes.button}
                                            onClick={() =>
                                                window.open(
                                                    home + "/pc/?year=" + p_year_string + "&filter=" + filter_string
                                                )
                                            }>
                                            Generate Pie Chart
                                        </Button>
                                        <Button
                                            fullWidth
                                            disabled={filter_and_year_not_set}
                                            className={classes.button}
                                            onClick={() =>
                                                window.open(
                                                    home + "/gd/?year=" + p_year_string + "&filter=" + filter_string
                                                )
                                            }>
                                            Generate Geography Data
                                        </Button>
                                    </Grid>
                                    <Grid item className={classes.gridtop} xs={12} md={6} lg={3} xl={3}>
                                        <Typography variant="h3">Staff Proposal Evaluation</Typography>
                                        <br />
                                        <Typography id="eval-order-checkbox-label">Select Ordering</Typography>
                                        <Select
                                            native
                                            className={classes.selectbox}
                                            labelId="eval-order-checkbox-label"
                                            id="eval-order-checkbox"
                                            fullWidth
                                            value={eval_order}
                                            onChange={this.handleChangeEvalOrder}
                                            input={<Input />}>
                                            <option></option>
                                            {valid_eval_order.map(order => (
                                                <option value={order}>{order}</option>
                                            ))}
                                        </Select>
                                        <Button
                                            fullWidth
                                            disabled={ordering_and_year_not_set}
                                            className={classes.button}
                                            onClick={() =>
                                                window.open(home + "/spe/?year=" + p_year_string + spe_cat_string)
                                            }>
                                            Staff Proposal Evaluation
                                        </Button>
                                    </Grid>
                                    <Grid item className={classes.gridtop} xs={12} md={6} lg={3} xl={3}>
                                        <Typography variant="h3">All Proposal Data</Typography>
                                        <Button
                                            fullWidth
                                            disabled={year_not_set}
                                            className={classes.button}
                                            onClick={() => window.open(home + "/apd/?year=" + p_year_string)}>
                                            All Proposal Data
                                        </Button>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={false}>
                            <ExpansionPanelSummary
                                className={classes.expansionSummaryRoot}
                                expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3">
                                    <AssessmentIcon className={classes.expansionIcon} /> Proposal Member Review Process
                                    Reports
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={16}>
                                    <Grid item xs={12}>
                                        <Grid item xs={12} md={6} lg={3} xl={3}>
                                            <p></p>
                                            <Typography variant="h3">Select Year:</Typography>
                                            <Select
                                                className={classes.selectbox}
                                                labelId="mrp-years-checkbox-label"
                                                id="mrp-years-checkbox"
                                                //multiple
                                                fullWidth
                                                value={mrp_year}
                                                disabled={valid_years.length === 0}
                                                onChange={this.handleChangeMRPYear}
                                                input={<Input />}
                                                renderValue={selected => selected}
                                                >
                                                {valid_years.filter(x => x !== "All").map(year => (
                                                    <MenuItem key={"mrp_" + year} value={year}>
                                                        <Checkbox color="primary" checked={mrp_year.indexOf(year) > -1} />
                                                        <ListItemText primary={year} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <p></p>
                                            <Typography variant="h3">Select Report :</Typography>
                                            <Select
                                                className={classes.selectbox}
                                                labelId="mrp-report-label"
                                                id="mrp-report"
                                                fullWidth
                                                value={mrp_report}
                                                onChange={this.handleChangeMRPReport}
                                                input={<Input />}>
                                                {valid_mrp_report.map(format => (
                                                    <MenuItem key={format} value={format}>
                                                        <ListItemText primary={format} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <p></p>
                                            <Typography variant="h3">Select Sorting :</Typography>
                                            <Select
                                                className={classes.selectbox}
                                                labelId="mrp-sort-label"
                                                disabled={hide_mrp_sort}
                                                id="mrp-sort"
                                                fullWidth
                                                value={mrp_sort}
                                                onChange={this.handleChangeMRPSort}
                                                input={<Input />}>
                                                {valid_mrp_sort.map(filter => (
                                                    <MenuItem key={filter} value={filter}>
                                                        <ListItemText primary={filter} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <p></p>
                                            <Button
                                                fullWidth
                                                disabled={hide_mrp_button}
                                                className={classes.button}
                                                onClick={() => window.open(mrp_string)}>
                                                Member Review Process Report
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={false}>
                            <ExpansionPanelSummary
                                className={classes.expansionSummaryRoot}
                                expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3">
                                    <AssessmentIcon className={classes.expansionIcon} /> Work Plan Reports
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={16}>
                                    <Grid item xs={12}>
                                        <Grid item xs={12} md={6} lg={3} xl={3}>
                                            <p></p>
                                            <Typography
                                                id="dw-years-checkbox-label"
                                                variant="h3"
                                                color={valid_dw_years.length === 0 ? "error" : "initial"}>
                                                {valid_dw_years.length === 0
                                                    ? "No Draft Work Plans Entered"
                                                    : "Draft Work Plans"}
                                            </Typography>
                                            <p></p>
                                            <Typography id="dw-years-checkbox-label" variant="h3">
                                                Select Years :
                                            </Typography>
                                            <Select
                                                className={classes.selectbox}
                                                labelId="dw-years-checkbox-label"
                                                id="dw-years-checkbox"
                                                multiple
                                                fullWidth
                                                value={dw_year}
                                                disabled={valid_dw_years.length === 0}
                                                onChange={this.handleChangeDWYear}
                                                input={<Input />}
                                                renderValue={selected => selected.join(", ")}>
                                                <MenuItem key="reset" value="reset">
                                                    <Button>Reset Values</Button>
                                                </MenuItem>
                                                {valid_dw_years.map(year => (
                                                    <MenuItem key={year} value={year}>
                                                        <Checkbox color="primary" checked={dw_year.indexOf(year) > -1} />
                                                        <ListItemText primary={year} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.gridtop} xs={12} md={6} lg={3} xl={3}>
                                        <Typography variant="h3">Batches</Typography>
                                        <br />
                                        <Typography id="dw-batch-format-checkbox-label">Select Format(s)</Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="dw-batch-format-checkbox-label"
                                            id="dw-batch-format-checkbox"
                                            multiple
                                            fullWidth
                                            value={dw_batch_formats}
                                            onChange={this.handleChangeDWBatchFormat}
                                            input={<Input />}
                                            renderValue={selected => selected.join(", ")}>
                                            {valid_batch_formats.map(format => (
                                                <MenuItem key={format} value={format}>
                                                    <Checkbox color="primary" checked={dw_batch_formats.indexOf(format) > -1} />
                                                    <ListItemText primary={format} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Button
                                            fullWidth
                                            disabled={dw_format_and_year_not_set}
                                            className={classes.button}
                                            onClick={() =>
                                                window.open(
                                                    home +
                                                        "/bw/?year=" +
                                                        dw_year_string +
                                                        "&ff=" +
                                                        dw_format_string +
                                                        "&draft=y"
                                                )
                                            }>
                                            Batch Draft Work Plans
                                        </Button>
                                    </Grid>
                                    <Grid item className={classes.gridtop} xs={12} md={6} lg={3} xl={3}>
                                        <Typography variant="h3">Peer Review</Typography>
                                        <Button
                                            fullWidth
                                            disabled={dw_year_not_set}
                                            className={classes.button}
                                            onClick={() =>
                                                window.open(home + "/prwt/?year=" + dw_year_string + "&draft=y")
                                            }>
                                            Draft Peer Review
                                        </Button>
                                    </Grid>
                                    <Grid item className={classes.gridtop} xs={12} md={6} lg={3} xl={3}>
                                        <Typography variant="h3">Work Plan Review</Typography>
                                        <Button
                                            fullWidth
                                            disabled={dw_year_not_set}
                                            className={classes.button}
                                            onClick={() =>
                                                window.open(home + "/wrt/?year=" + dw_year_string + "&draft=y")
                                            }>
                                            Draft Work Plan Review
                                        </Button>
                                    </Grid>
                                    <Grid item className={classes.gridtop} xs={12} md={6} lg={3} xl={3}>
                                        <Typography variant="h3">Work Plan Data</Typography>
                                        <Button
                                            fullWidth
                                            disabled={dw_year_not_set}
                                            className={classes.button}
                                            onClick={() =>
                                                window.open(home + "/awd/?year=" + dw_year_string + "&draft=y")
                                            }>
                                            All Draft Work Plan Data
                                        </Button>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                            <hr />
                            <ExpansionPanelDetails>
                                <Grid container spacing={16}>
                                    <Grid item xs={12}>
                                        <Grid item xs={12} md={6} lg={3} xl={3}>
                                            <p></p>
                                            <Typography
                                                id="fw-years-checkbox-label"
                                                variant="h3"
                                                color={valid_fw_years.length === 0 ? "error" : "initial"}>
                                                {valid_fw_years.length === 0
                                                    ? "No Final Work Plans Entered"
                                                    : "Final Work Plans"}
                                            </Typography>
                                            <p></p>
                                            <Typography id="fw-years-checkbox-label" variant="h3">
                                                Select Years :
                                            </Typography>
                                            <Select
                                                className={classes.selectbox}
                                                labelId="fw-years-checkbox-label"
                                                id="fw-years-checkbox"
                                                multiple
                                                fullWidth
                                                value={fw_year}
                                                disabled={valid_fw_years.length === 0}
                                                onChange={this.handleChangeFWYear}
                                                input={<Input />}
                                                renderValue={selected => selected.join(", ")}>
                                                <MenuItem key="reset" value="reset">
                                                    <Button>Reset Values</Button>
                                                </MenuItem>
                                                {valid_fw_years.map(year => (
                                                    <MenuItem key={year} value={year}>
                                                        <Checkbox color="primary" checked={fw_year.indexOf(year) > -1} />
                                                        <ListItemText primary={year} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.gridtop} xs={12} md={6} lg={3} xl={3}>
                                        <Typography variant="h3">Batches</Typography>
                                        <br />
                                        <Typography id="fw-batch-format-checkbox-label">Select Format(s)</Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="fw-batch-format-checkbox-label"
                                            id="fw-batch-format-checkbox"
                                            multiple
                                            fullWidth
                                            value={fw_batch_formats}
                                            onChange={this.handleChangeFWBatchFormat}
                                            input={<Input />}
                                            renderValue={selected => selected.join(", ")}>
                                            {valid_batch_formats.map(format => (
                                                <MenuItem key={format} value={format}>
                                                    <Checkbox color="primary" checked={fw_batch_formats.indexOf(format) > -1} />
                                                    <ListItemText primary={format} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Button
                                            fullWidth
                                            disabled={fw_format_and_year_not_set}
                                            className={classes.button}
                                            onClick={() =>
                                                window.open(
                                                    home +
                                                        "/bw/?year=" +
                                                        fw_year_string +
                                                        "&ff=" +
                                                        fw_format_string +
                                                        "&draft=n"
                                                )
                                            }>
                                            Batch Final Work Plans
                                        </Button>
                                    </Grid>
                                    <Grid item className={classes.gridtop} xs={12} md={6} lg={3} xl={3}>
                                        <Typography variant="h3">Peer Review</Typography>
                                        <Button
                                            fullWidth
                                            disabled={fw_year_not_set}
                                            className={classes.button}
                                            onClick={() =>
                                                window.open(home + "/prwt/?year=" + fw_year_string + "&draft=n")
                                            }>
                                            Final Peer Review
                                        </Button>
                                    </Grid>
                                    <Grid item className={classes.gridtop} xs={12} md={6} lg={3} xl={3}>
                                        <Typography variant="h3">Work Plan Review</Typography>
                                        <Button
                                            fullWidth
                                            disabled={fw_year_not_set}
                                            className={classes.button}
                                            onClick={() =>
                                                window.open(home + "/wrt/?year=" + fw_year_string + "&draft=n")
                                            }>
                                            Final Work Plan Review
                                        </Button>
                                    </Grid>
                                    <Grid item className={classes.gridtop} xs={12} md={6} lg={3} xl={3}>
                                        <Typography variant="h3">Work Plan Data</Typography>
                                        <Button
                                            fullWidth
                                            disabled={fw_year_not_set}
                                            className={classes.button}
                                            onClick={() =>
                                                window.open(home + "/awd/?year=" + fw_year_string + "&draft=n")
                                            }>
                                            All Final Work Plan Data
                                        </Button>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={false}>
                            <ExpansionPanelSummary
                                className={classes.expansionSummaryRoot}
                                expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3">
                                    <AssessmentIcon className={classes.expansionIcon} /> Appropriations Reports
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={16}>
                                    <Grid item xs={12}>
                                        <Grid item xs={12} md={6} lg={3} xl={3}>
                                            <p></p>
                                            <Typography id="proposal-years-checkbox-label" variant="h3">
                                                Select Years :
                                            </Typography>
                                            <Select
                                                className={classes.selectbox}
                                                labelId="leg-years-checkbox-label"
                                                id="leg-years-checkbox"
                                                fullWidth
                                                multiple
                                                value={leg_years}
                                                disabled={valid_years.length === 0}
                                                onChange={this.handleChangeLegYear}
                                                input={<Input />}
                                                renderValue={selected => selected.join(", ")}>                                                
                                                <MenuItem key="reset" value="reset">
                                                    <Button>Reset Values</Button>
                                                </MenuItem>
                                                {valid_years.map(year => (
                                                    <MenuItem key={year} value={year}>
                                                        <Checkbox color="primary" checked={leg_years.indexOf(year) > -1} />
                                                        <ListItemText primary={year} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.gridtop} xs={12} md={6} lg={3} xl={3}>
                                        <Typography variant="h3"> Appropriations Tables</Typography>
                                        <Button
                                            fullWidth
                                            disabled={leg_year_not_set}
                                            className={classes.button}
                                            onClick={() => window.open(home + "/lt/?year=" + leg_year_string)}>
                                            Generate Appropriations Table
                                        </Button>
                                        <Button
                                            fullWidth
                                            disabled={leg_year_not_set}
                                            className={classes.button}
                                            onClick={() => window.open(home + "/lc/?year=" + leg_year_string)}>
                                            Generate Appropriations Pie Chart
                                        </Button>
                                        <Button
                                            fullWidth
                                            disabled={leg_year_not_set}
                                            className={classes.button}
                                            onClick={() => window.open(home + "/lg/?year=" + leg_year_string)}>
                                            Generate Appropriations Geography Data
                                        </Button>
                                    </Grid>
                                    <Grid item className={classes.gridtop} xs={12} md={6} lg={3} xl={3}>
                                        <Typography variant="h3">All Appropriations Data</Typography>
                                        <Button
                                            fullWidth
                                            disabled={leg_year_not_set}
                                            className={classes.button}
                                            onClick={() => window.open(home + "/ald/?year=" + leg_year_string)}>
                                            Generate All Appropriations Data
                                        </Button>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={false}>
                            <ExpansionPanelSummary
                                className={classes.expansionSummaryRoot}
                                expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3">
                                    <AssessmentIcon className={classes.expansionIcon} /> Active Projects Reports
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={16}>
                                    <Grid item xs={12} md={4} lg={3} xl={2}>
                                        <Typography variant="h3" gutterBottom><b>Status Updates</b></Typography>
                                        <Typography variant="h3">
                                            Select Date Range:
                                        </Typography>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <MuiDatePicker
                                                value={su_date_from}
                                                onChange={this.handleSUFrom}
                                                openTo="year"
                                                views={["year", "month", "day"]}
                                                keyboard
                                                format="MM/dd/yyyy"
                                                InputProps={InputProps}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        root: classes.rootLabel,
                                                        shrink: classes.rootLabelShrink
                                                    }
                                                }}
                                                clearable
                                                field="status_update_from"
                                                label="From"
                                            />
                                        </MuiPickersUtilsProvider>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <MuiDatePicker
                                                value={su_date_to}
                                                onChange={this.handleSUTo}
                                                openTo="year"
                                                views={["year", "month", "day"]}
                                                keyboard
                                                format="MM/dd/yyyy"
                                                InputProps={InputProps}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        root: classes.rootLabel,
                                                        shrink: classes.rootLabelShrink
                                                    }
                                                }}
                                                clearable
                                                field="status_update_to"
                                                label="To"
                                            />
                                        </MuiPickersUtilsProvider>
                                        <br/><br/>
                                        <Typography id="su-staff-checkbox-label" variant="h3">
                                            Select Staff:
                                        </Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="su-staff-checkbox-label"
                                            id="su-staff-checkbox"
                                            fullWidth
                                            multiple
                                            value={su_staff}
                                            onChange={this.handleSUStaff}
                                            input={<Input />}
                                            renderValue={selected => selected.join(", ")}>
                                            <MenuItem key="reset" value="reset">
                                                <Button>Reset Values</Button>
                                            </MenuItem>
                                            {valid_staff.map(year => (
                                                <MenuItem key={year} value={year}>
                                                    <Checkbox color="primary" checked={su_staff.indexOf(year) > -1} />
                                                    <ListItemText primary={year} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Typography id="su-years-checkbox-label" variant="h3">
                                            Select RFP Year:
                                        </Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="su-years-checkbox-label"
                                            id="su-years-checkbox"
                                            fullWidth
                                            multiple
                                            value={su_year}
                                            disabled={valid_years.length === 0}
                                            onChange={this.handleSUYear}
                                            input={<Input />}
                                            renderValue={selected => selected.join(", ")}>
                                            <MenuItem key="reset" value="reset">
                                                <Button>Reset Values</Button>
                                            </MenuItem>
                                            {valid_years.map(year => (
                                                <MenuItem key={year} value={year}>
                                                    <Checkbox color="primary" checked={su_year.indexOf(year) > -1} />
                                                    <ListItemText primary={year} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormControlLabel control={<Checkbox onChange={this.handleSUCheckbox} checked={su_checkbox} />} label="Only include not submitted and past due date." />
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => window.open(home + "/status_update_report/" + su_string)}>
                                            Generate Project Status Report
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={3} xl={2}>
                                        <Typography variant="h3" gutterBottom><b>Final Reports</b></Typography>
                                        <Typography id="fr-years-checkbox-label" variant="h3">
                                            Select Calendar Year:
                                        </Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="fr-years-checkbox-label"
                                            id="fr-years-checkbox"
                                            fullWidth
                                            multiple
                                            value={fr_year}
                                            onChange={this.handleFRYear}
                                            input={<Input />}
                                            renderValue={selected => selected.join(", ")}>
                                            <MenuItem key="reset" value="reset">
                                                <Button>Reset Values</Button>
                                            </MenuItem>
                                            {fr_years.map(year => (
                                                <MenuItem key={year} value={year}>
                                                    <Checkbox color="primary" checked={fr_year.indexOf(year) > -1} />
                                                    <ListItemText primary={year} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Button
                                            fullWidth
                                            disabled={fr_year_not_set}
                                            className={classes.button}
                                            onClick={() => window.open(home + "/final_reports/" + fr_year_string)}>
                                            Generate Final Reports Table
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={3} xl={2}>
                                        <Typography variant="h3" gutterBottom><b>All Data Active Projects</b></Typography>
                                        <Typography id="ap-years-checkbox-label" variant="h3">
                                            Select RFP Year:
                                        </Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="ap-years-checkbox-label"
                                            id="ap-years-checkbox"
                                            fullWidth
                                            multiple
                                            value={ap_year}
                                            onChange={this.handleAPYear}
                                            input={<Input />}
                                            renderValue={selected => selected.join(", ")}>
                                            <MenuItem key="reset" value="reset">
                                                <Button>Reset Values</Button>
                                            </MenuItem>
                                            {valid_ap_years.map(year => (
                                                <MenuItem key={year} value={year}>
                                                    <Checkbox color="primary" checked={ap_year.indexOf(year) > -1} />
                                                    <ListItemText primary={year} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Button
                                            fullWidth
                                            disabled={ap_year_not_set}
                                            className={classes.button}
                                            onClick={() => window.open(home + "/active_projects/" + ap_year_string)}>
                                            Generate All Data Active Projects Report
                                        </Button>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={false}>
                            <ExpansionPanelSummary
                                className={classes.expansionSummaryRoot}
                                expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3">
                                    <AssessmentIcon className={classes.expansionIcon} /> Admin Reports
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={16}>
                                    <Grid item xs={2}>
                                        <Typography variant="h3" gutterBottom><b>Draft Bill Language</b></Typography>
                                        <Typography id="leg-years-checkbox-label" variant="h3">
                                            Select Bill Year:
                                        </Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="leg-years-checkbox-label"
                                            id="leg-years-checkbox"
                                            fullWidth
                                            value={dbl_year}
                                            disabled={valid_leg_years.length === 0}
                                            onChange={this.handleChangeDBLYear}
                                            input={<Input />}
                                            renderValue={selected => selected.join(", ")}>
                                            {valid_leg_years.map(year => (
                                                <MenuItem key={year} value={year}>
                                                    <Checkbox color="primary" checked={dbl_year.indexOf(year) > -1} />
                                                    <ListItemText primary={year} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Button
                                            fullWidth
                                            disabled={dbl_year_not_set}
                                            className={classes.button}
                                            onClick={() => window.open(home + "/dbl/?year=" + dbl_year_string)}>
                                            Generate Draft Bill Language
                                        </Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="h3" gutterBottom><b>All Subject Data</b></Typography>
                                        <Typography id="subject-years-checkbox-label" variant="h3">
                                            Select RFP Year:
                                        </Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="subject-years-checkbox-label"
                                            id="subject-years-checkbox"
                                            fullWidth
                                            multiple
                                            value={subject_year}
                                            onChange={this.handleSubjectYear}
                                            input={<Input />}
                                            renderValue={selected => selected.join(", ")}>
                                            <MenuItem key="reset" value="reset">
                                                <Button>Reset Values</Button>
                                            </MenuItem>
                                            {valid_years.map(year => (
                                                <MenuItem key={year} value={year}>
                                                    <Checkbox color="primary" checked={subject_year.indexOf(year) > -1} />
                                                    <ListItemText primary={year} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Button
                                            fullWidth
                                            disabled={subject_year_not_set}
                                            className={classes.button}
                                            onClick={() => window.open(home + "/subjects_table/" + subject_year_string)}>
                                            All Subjects Table
                                        </Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="h3" gutterBottom><b>Legacy/Drupal Data</b></Typography>
                                        <Typography id="api-years-checkbox-label" variant="h3">
                                            Select RFP Year:
                                        </Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="api-years-checkbox-label"
                                            id="api-years-checkbox"
                                            fullWidth
                                            value={api_year}
                                            onChange={this.handleChangeAPIYear}
                                            input={<Input />}
                                            renderValue={selected => selected.join(", ")}>
                                            {valid_years.map(year => (
                                                <MenuItem key={year} value={year}>
                                                    <Checkbox color="primary" checked={api_year.indexOf(year) > -1} />
                                                    <ListItemText primary={year} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Typography id="api-format-label" variant="h3">Select Format:</Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="api-format-label"
                                            id="api-format"
                                            fullWidth
                                            value={api_format}
                                            onChange={this.handleChangeAPIReport}
                                            input={<Input />}>
                                            {api_formats.map(format => (
                                                <MenuItem key={format} value={format}>
                                                    <ListItemText primary={format} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Button
                                            fullWidth
                                            disabled={api_not_set}
                                            className={classes.button}
                                            onClick={() => window.open(home + "/api_export/" + api_year + "/" + api_format)}>
                                            Generate Legacy Website Table
                                        </Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="h3" gutterBottom><b>Land Acquisition (To Do Phase 6)</b></Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="h3" gutterBottom><b>All Projects Data</b></Typography>
                                        <Typography id="allproj-years-checkbox-label" variant="h3">
                                            Select RFP Year:
                                        </Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="allproj-years-checkbox-label"
                                            id="allproj-years-checkbox"
                                            fullWidth
                                            multiple
                                            value={allproj_year}
                                            disabled={valid_years.length === 0}
                                            onChange={this.handleChangeALLPROJYear}
                                            input={<Input />}
                                            renderValue={selected => selected.join(", ")}>
                                            
                                            <MenuItem key="reset" value="reset">
                                                <Button>Reset Values</Button>
                                            </MenuItem>
                                            {valid_years.map(year => (
                                                <MenuItem key={year} value={year}>
                                                    <Checkbox color="primary" checked={allproj_year.indexOf(year) > -1} />
                                                    <ListItemText primary={year} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Button
                                            fullWidth
                                            disabled={allproj_year_not_set}
                                            className={classes.button}
                                            onClick={() => window.open(home + "/all_projects/" + allproj_year_string)}>
                                            Generate All Project Data
                                        </Button>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={false}>
                            <ExpansionPanelSummary
                                className={classes.expansionSummaryRoot}
                                expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3">
                                    <AssessmentIcon className={classes.expansionIcon} /> API
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={16}>
                                    <Typography variant="h3">To Do (Phase 6)</Typography>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={false}>
                            <ExpansionPanelSummary
                                className={classes.expansionSummaryRoot}
                                expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3">
                                    <AssessmentIcon className={classes.expansionIcon} /> Forms
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={16}>
                                    <Typography variant="h3">To Do (Phase 6)</Typography>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

Reports = connect(
    (state, ownProps) => ({
        authState: state.auth,
        all_proposals: getAllProposals(state, ownProps),
        all_draft_workplans: getAllDraftWorkplans(state, ownProps),
        all_final_workplans: getAllFinalWorkplans(state, ownProps),
        staff: getStaff(state, ownProps)
    }),
    null
)(Reports);

export default withStyles(styles)(withRouter(Reports));
